<template>
    <div class="pt-3">
        <h4>typy aktivit</h4>



        <div class="d-flex w-50 py-2">

            <div class="col">
                <label>Nova aktivita </label>
                <input class='form-control' type="text" v-model="new_name"/>
            </div>
            <div class="col">
                <button type="button" @click="createL1()" class="btn btn-primary mt-4">Pridat</button>
            </div>
        </div>

        <table class="table table-striped">

            <tr v-for="t in types" :key="t.id">
                <td># {{t.id}}</td>
                <td>{{t.title}}</td>
                <td>
                    <div v-for="s in t.steps" :key="s.id" class="krok"><a href="#" @click.prevent="editStep(s)" >{{s.title}}</a></div>
                    <div v-if="t.id>0" class="mt-3"><a class='btn btn-secondary' href="#" @click.prevent="newStep(t)">Pridat krok</a></div>



                </td>
                <td><a href="#" @click.prevent="destroy(t)">smazat</a></td>
            </tr>
        </table>
<modal name="hello-world" :adaptive="true" :draggable="true" height="auto">
  <div class="p-2">
      <div class="form-group">
          <label>Nazev</label>
            <input type="text" class="form-control" v-model="new_item.title">
      </div>

      <div class="form-group">
        <label>Popis</label>
          <ckeditor :editor="editor" v-model="new_item.description" :config="editorConfig"></ckeditor>
      </div>
      <div class="d-flex">
          <div class="ml-auto">
              <button class="btn btn-primary" @click="saveActStep()">Ulozit</button>
          </div>
      </div>


  </div>

</modal>
    </div>
</template>

<script>
    import vmodal from 'vue-js-modal'
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    const axios = require("axios");
    axios.defaults.withCredentials = true;

    import Vue from 'vue'
    Vue.use(CKEditor);

    export default {
        name: "ActivityTypes",
        comments:{
            vmodal
        },
        data(){
            return{
                editor: ClassicEditor,
                editorConfig: {

                },
                new_name:'',
                new_atype_id:null,
                new_text:'',
                new_st_name:'',
                types:[],
                new_item:[]
            }
        },
        methods:{
            editStep(s){
                this.new_item=s
                this.$modal.show('hello-world');
            },
            saveActStep(){

                let data= {
                    title: this.new_item.title,
                    description: this.new_item.description,
                    parent_id: this.new_item.parent_id
                }
                console.log('save ', this.new_item.id)

                if (this.new_item.id && this.new_item.id>0) {
                    axios.put(process.env.VUE_APP_API_ROOT_URL + "/activity_types/"+this.new_item.id, data).then((result) => {
                        if (result.status == 200) {
                            console.log("ok", result.data)
                        }
                    })
                }else{
                    //insert
                    axios.post(process.env.VUE_APP_API_ROOT_URL + "/activity_types", data).then((result) => {
                        if (result.status == 201) {
                            console.log("ok", result.data)
                            let d = result.data
                            for(var i=0; i<this.types.length; i++){
                                if (this.types[i].id == d.parent_id){
                                    this.types[i].steps.push(result.data)
                                    this.$modal.hide('hello-world');
                                }
                            }

                        }
                    })

                }
            },
            createL1(){
                axios.post(process.env.VUE_APP_API_ROOT_URL + "/activity_types", {
                    title: this.new_name,
                    parent_id: 0
                }).then((result) => {
                    if (result.status ==201){
                        console.log("ok", result.data)
                        if (result.data.id>0){
                            console.log("add", result.data)
                            this.types.push(result.data)
                        }
                    }
                }).catch((err) => {
                    console.error(err)
                });



                this.new_name=''
            },
            newStep(actType){
                this.new_item=[]
                this.new_item.id=null
                this.new_item.parent_id=actType.id
                this.$modal.show('hello-world');
            },

            destroy(t){
                axios.delete(process.env.VUE_APP_API_ROOT_URL + "/activity_types/"+t.id).then((result) => {
                    if (result.status ==200){
                        console.log("ok")
                    }
                }).catch((err) => {
                    console.error(err)
                });
            },
          _reload(){
                axios.get(process.env.VUE_APP_API_ROOT_URL + "/activity_types").then((result) => {

                if (result.status ==200){
                    this.types = result.data
                }
            }).catch((err) => {
                console.error(err)
            });
          }
        },
        mounted() {
            this._reload()
        }


    }
</script>

<style>
    .ck-editor__editable {
        min-height: 400px;
    }


</style>
<style scoped>
    .krok{
        padding:10px;
        border-bottom: 1px solid silver;

    }
</style>